import React, { useState } from "react"
import styled from "styled-components"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  EffectFade,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
/* import "swiper/components/effect-fade/effect-fade.min.css" */

const Container = styled.div`
  .slider {
    background: #f6f9fb;
    display: flex;
    position: relative;
    padding-bottom: 104px;
    overflow: hidden;
  }
  .swiper-text-slide {
    width: 100%;
    height: auto;
    opacity: 0 !important;
    &.swiper-slide-active {
      opacity: 1 !important;
      &.no-bg {
        .swiper-text-body {
          opacity: 0;
          background: none;
        }
      }
    }
    &.bg-white {
      background: #fff !important;
    }
  }
  .slider-navigation {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(calc(-50% - 50px));
    width: 22px;
    z-index: 1000;
    img {
      width: 100%;
    }
  }
  .slider-prev {
    display: none;
    left: 5%;
  }
  .slider-next {
    right: 5%;
  }
  .previous,
  .main,
  .next {
    padding: 0;
  }
  .previous {
    display: none;
  }
  .main {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    padding: 14px 0 0;
    position: relative;
  }
  .swiper-main {
  }
  .swiper-image-main {
    position: relative;
    a {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 10;
    }
  }
  .text {
    // background: #fff;
    position: absolute;
    left: 30px;
    right: -15px;
    bottom: -50px;
    z-index: 5;
  }
  .next {
    padding-left: 30px;
    flex: 1;
    //max-width: 400px;
    max-width: 100vw;
  }
  .swiper-slide img {
    width: 100%;
  }
  .swiper-text-body {
    // background: #ffffff;
    background: url("/quote.svg") 20px 20px no-repeat;
    padding: 50px 20px 20px;
    font-size: 10px;
    line-height: 130%;
    color: #000000;
    width: 100%;
  }
  .swiper-text-body__title {
    font-weight: 600;
    padding-bottom: 15px;
    text-transform: uppercase;
  }
  .swiper-text-body__description {
    font-size: 10px;
  }
  .slider-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
  }
  @media screen and (min-width: 760px) {
    .slider {
      display: block;
      padding-bottom: 118px;
    }
    .previous {
      display: block;
      width: 65%;
      transform: translateX(-77%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .main {
      margin: 0 auto;
      width: 65%;
      max-width: 65%;
      padding: 50px;
    }
    .next {
      display: block;
      width: 65%;
      transform: translateX(77%);
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      max-width: 100%;
      padding-left: 0;
    }
    .previous,
    .next {
      .swiper-slide {
        background: #000;
        img {
          display: block;
          opacity: 0.55;
        }
      }
    }
    .slider-prev {
      display: block;
    }
    .text {
      right: 30px;
      bottom: -50px;
      width: 260px;
      left: auto;
    }
    &.no-bg {
      .swiper-text-body {
        opacity: 0;
        background: none;
      }
    }
    .swiper-text-body {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      background: url("/quote.svg") 30px 35px no-repeat;
      // background: url('~assets/svg/quote.svg') 30px 35px no-repeat #fff;
      padding: 70px 30px 30px;
    }
    .swiper-text-body__description {
      font-size: 14px;
    }
    .swiper-text-body__title {
      order: 2;
      padding-bottom: 0;
      padding-top: 20px;
      text-transform: none;
    }
  }
  @media screen and (min-width: 1200px) {
    .slider-wrapper {
      background: #f6f9fb;
      padding-bottom: 200px;
      &::after {
        width: 40px;
        right: 0;
        top: 0;
        bottom: 0;
        background: #fff;
        content: "";
        position: absolute;
      }
      &::before {
        width: 40px;
        left: 0;
        top: 0;
        bottom: 0;
        background: #fff;
        content: "";
        position: absolute;
      }
    }
    .main {
      padding: 100px 195px 65px;
    }
    .slider-prev {
      left: 6.5%;
    }
    .slider-next {
      right: 6.5%;
    }
  }
  @media screen and (min-width: 1441px) {
    .slider-wrapper {
    }
    .slider {
      margin: 0 auto;
      max-width: 1440px;
      width: 100%;
      overflow: hidden;
      padding-bottom: 100px;
    }
    .main,
    .previous,
    .next {
      width: 50%;
    }
    .main {
      padding: 80px 90px 30px;
    }
    .slider-navigation {
      margin-top: 20px;
      width: 14px;
    }
    .slider-prev {
      left: 8.5%;
    }
    .slider-next {
      right: 8.5%;
    }
    .previous {
      transform: translateX(-60%);
    }
    .next {
      transform: translateX(60%);
    }
    .text {
      width: 320px;
    }
  }
`
const sliders = [
  {
    title: "Susana Ordaya",
    description:
      "Gracias al programa, no solo he enriquecido mi conocimiento técnico en estadística y ciencia de datos, sino que también he podido desarrollar habilidades interpersonales que son clave para mi crecimiento profesional.",
    background: true,
    image: "/images/slider/susana-ordaya.jpg",
  },
  {
    title: "Jason Martínez",
    description:
      "El programa me ha permitido ingresar al mundo de la ciencia de datos y mejorar mis habilidades técnicas y valores humanos para ayudar a resolver problemas sociales que afectan a millones de personas.",
    background: true,
    image: "/images/slider/jason-martinez-min.jpg",
  },
  {
    title: "",
    description: "",
    background: false,
    image: "/images/slider/50.jpg",
  },
  {
    title: "José Mallma",
    description:
      "El programa es una gran oportunidad, me ha abierto las puertas para desarrollar una carrera en ciencia de datos. La calidad del contenido y los invitados han superado mis expectativas. ",
    background: true,
    image: "/images/slider/jose-mallma-min.jpg",
  },
  {
    title: "David Ascencios",
    description:
      "¡Ha sido un año emocionante! Estoy muy agradecido de tener la oportunidad de estudiar con este grupo de personas increíbles y talentosas, además de aprender de ellos y las diferentes habilidades que cada uno aporta.",
    background: true,
    image: "/images/slider/david-ascensios-min.jpg",
  },
  {
    title: "",
    description: "",
    background: false,
    image: "/images/slider/85.jpg",
  },
  {
    title: "Jesús Figueroa",
    description:
      "Este programa significa para mí una gran oportunidad de incorporarme a un campo que siempre me había interesado, pero en el que no sabía cómo comenzar. ",
    background: true,
    image: "/images/slider/jesus-figueroa-min.jpg",
  },
  {
    title: "Karen Velásquez",
    description:
      "Gracias al programa he aprendido conceptos teóricos y habilidades que me permitirán convertirme en científico de datos. Además, me emociona saber que podremos ayudar a ONGs en nuestro país. ",
    background: true,
    image: "/images/slider/karen-velasquez-min.jpg",
  },
  {
    title: "",
    description: "",
    background: false,
    image: "/images/slider/91100.jpg",
  },
  {
    title: "",
    description: "",
    background: false,
    image: "/images/slider/98.jpg",
  },
]

const SliderPrueba = () => {
  SwiperCore.use([Autoplay, EffectFade])

  return (
    <Container>
      <div className="slider-wrapper">
        <div className="slider">
          <div className="slider-navigation slider-prev">
            <img src="/arrow-left-white.svg" alt="Anterior" />
          </div>
          <div className="slider-navigation slider-next">
            <img src="/arrow-right-white.svg" alt="Siguiente" />
          </div>
          <div className="previous">
            <div className="swiper-prev swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <Swiper
                    slidesPerView={1}
                    loop
                    autoplay={true}
                    speed={1000}
                    allowTouchMove={false}
                    initialSlide={sliders.length - 1}
                  >
                    {sliders.map(slider => (
                      <SwiperSlide>
                        <img src={slider.image} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
          <div className="main">
            <div className="swiper-main swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-image-main swiper-slide">
                  <Swiper
                    slidesPerView={1}
                    loop
                    autoplay={true}
                    speed={1000}
                    allowTouchMove={false}
                  >
                    {sliders.map(slider => (
                      <SwiperSlide>
                        <img src={slider.image} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
            <div className="text">
              <div className="swiper-text swiper-container">
                <div className="swiper-wraper">
                  <Swiper
                    slidesPerView={1}
                    loop
                    autoplay={true}
                    speed={1000}
                    allowTouchMove={false}
                    /* effect="fade" */
                  >
                    {sliders.map(slider => (
                      <SwiperSlide
                        className={slider.background ? "bg-white" : "no-bg"}
                      >
                        <div className="swiper-text-body">
                          <div className="swiper-text-body__title">
                            {slider.title}
                          </div>
                          <div className="swiper-text-body__description">
                            {slider.description}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
          <div className="next">
            <div className="swiper-next swiper-container">
              <div className="swiper-wrapper">
                <Swiper
                  slidesPerView={1}
                  loop
                  autoplay={true}
                  speed={1000}
                  allowTouchMove={false}
                  initialSlide={1}
                >
                  {sliders.map(slider => (
                    <SwiperSlide>
                      <img src={slider.image} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SliderPrueba
