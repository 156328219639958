import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(400px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }
  
`;

const PartnersDiv = styled.div`
position: relative;
  margin-right: 2rem;
  margin-left: 2rem;
  width: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
 
  @media (min-width: 900px) {
    justify-content: space-around;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`
const PartnerCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  //height:8vw;
  margin-top: 1rem;
  margin-bottom: 2rem;
  justify-content: space-evenly;
  flex-wrap:wrap ;

  img {
    height:120px ;
    display: flex;
   
  }

  @media (max-width:870px){
    flex-direction: column;
    height:auto ;
    align-items: center;
    img{
      width:80%;
      height:auto ;
    }
    img:nth-child(2n){
      display:none;
    }
    img:nth-child(2n+1){
      display:flex;
    }
  }
 
  margin-right: auto;
  margin-left: auto;
 
`
const PartnerCard1 = styled(PartnerCard)`
  /* width: 18rem; */
  @media (min-width: 800px) {
    margin-top: 2.5rem;
  }
`

const Container = styled.div`
height: auto;
position: relative;
&.animate {
    animation: fade-up 4s;
  }
`

const PartnerTitle = styled.p`
  margin-right: 2rem;
  margin-left: 2rem;
  margin-top: 3rem;
  font-size: 28px;
  @media (min-width: 800px) {
    font-size: 40px;
    margin-left: 5rem;
  }
`
function Partners({data}) {

  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 3000) {
        setShow(true);
      } else {
        setShow(false);
      }}
      else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <Container className={show ? "animate" : ""}>
      <PartnerTitle>NUESTROS PARTNERS</PartnerTitle>
      <PartnersDiv>
        <PartnerCard
        /* data-sal="slide-up"
          data-sal-delay="100"
          data-sal-duration="1000"
          data-sal-easing="ease-in-out" */
        >
          {data?.map(item => {
            return(
              <>
              <img src={item.fields.file.url} alt={item.fields.file.fileName} />
              <img src="/sep.png" alt="-" />
              </>
            )
          })

          }
        {/*  <img src="/logo1.png" alt="fortunato brescia" />
          <img src="/sep.png" alt="-" />
          <img src="/logo2.png" alt="aporta" />
          <img src="/sep.png" alt="-" />
          <img src="/logo3.png" alt="MIT" />
          <img src="/sep.png" alt="-" />
          <img src="/logo4.png" alt="idss" />
          <img src="/sep.png" alt="-" />
          <img src="/logo5.png" alt="edx" />*/}
        </PartnerCard>
    
      </PartnersDiv>
    </Container>
  )
}

export default Partners
