import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FaChevronLeft, FaChevronRight, FaQuoteLeft } from "react-icons/fa"
import { IconContext } from "react-icons"

const SliderMainDiv = styled.div`
  width: 90%;
  padding-top: 3rem;
  margin-top: -2rem;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
  background-color: #f6f9fb;
  display: flex; /*
  justify-content: space-around;
  @media (min-width: 530px) {
    padding-top: 3rem;
  }*/
`
const SliderMainImg = styled.img`
  margin-right: auto;
  margin-left: auto;
  margin-top: 0rem;
  margin-bottom: 1rem;
  width: 80%;
  @media (min-width: 800px) {
    width: 70%;
    margin-top: 5rem;
    margin-bottom: 15rem;
  }
`
const SliderCentralDiv = styled.div`
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  width: 80%;
  @media (min-width: 800px) {
    width: 70%;
  }
`
//TODO: pequeño fix se alarga el espacio si no se tiene el mensaje
const SliderMsgDiv = styled.div`
  margin-left: auto;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 10rem;
  margin-top: -5rem;
  background-color: white; /*white;*/
  z-index: 10;
  font-size: 11px;
  text-align: justify;
  ${({ deactive }) => (deactive ? "padding: 5rem;" : "padding: 1rem;")}
  /* padding: 1rem; */
  ${({ deactive }) =>
    deactive &&
    `
    background-color: transparent !important;
  `}
  @media (min-width: 800px) {
    margin-left: auto;
    margin-right: 15%;
    display: flex;
    width: 70%;
    height: 35%;
    margin-top: -15rem;
    background-color: white;
    z-index: 10;
    font-size: 13px;
  }
  @media (min-width: 1210px) {
    margin-left: auto;
    margin-right: 8%;
    display: flex;
    width: 40%;
    height: 35%;
    margin-top: -25rem;
    background-color: white;
    z-index: 10;
    font-size: 15px;
  }

  @media (min-width: 1500px) {
    margin-top: -30rem;
  }
`

const SliderLeftImg = styled.div`
  width: 10%;
  height: auto;
  background-color: black;
  background: url(${props => props.img});
  background-size: cover;
  background-position-x: 100%;
  -webkit-filter: brightness(40%);
  filter: brightness(40%);
  display: flex;
  padding-top: 30%;
  justify-content: center;
  cursor: pointer;
  @media (min-width: 800px) {
    display: flex;
    width: 20%;
    height: 692px;
    padding-top: 300px;
  }
`
const SliderRigthImg = styled.div`
  width: 10%;
  height: auto;
  background-color: black;
  color: white;
  background: url(${props => props.img});
  background-size: cover;
  -webkit-filter: brightness(40%);
  filter: brightness(40%);
  display: flex;
  padding-top: 30%;
  justify-content: center;
  cursor: pointer;
  @media (min-width: 800px) {
    display: flex;
    width: 20%;
    height: 692px;
    padding-top: 300px;
  }
`
const NameDiv = styled.div`
  width: 100%;
  text-align: right;
  font-weight: bold;
`
const sliders = [
  {
    title: "Susana Ordaya",
    description:
      "Gracias al programa, no solo he enriquecido mi conocimiento técnico en estadística y ciencia de datos, sino que también he podido desarrollar habilidades interpersonales que son clave para mi crecimiento profesional.",
    background: true,
    image: "/images/slider/susana-ordaya.jpg",
  },
  {
    title: "Jason Martínez",
    description:
      "El programa me ha permitido ingresar al mundo de la ciencia de datos y mejorar mis habilidades técnicas y valores humanos para ayudar a resolver problemas sociales que afectan a millones de personas.",
    background: true,
    image: "/images/slider/jason-martinez-min.jpg",
  },
  {
    title: "",
    description: "",
    background: false,
    image: "/images/slider/sld1.png",
  },
  {
    title: "José Mallma",
    description:
      "El programa es una gran oportunidad, me ha abierto las puertas para desarrollar una carrera en ciencia de datos. La calidad del contenido y los invitados han superado mis expectativas. ",
    background: true,
    image: "/images/slider/jose-mallma-min.jpg",
  },
  {
    title: "David Ascencios",
    description:
      "¡Ha sido un año emocionante! Estoy muy agradecido de tener la oportunidad de estudiar con este grupo de personas increíbles y talentosas, además de aprender de ellos y las diferentes habilidades que cada uno aporta.",
    background: true,
    image: "/images/slider/david-ascensios-min.jpg",
  },
  {
    title: "",
    description: "",
    background: false,
    image: "/images/slider/sld2v2.png",
  },
  {
    title: "Jesús Figueroa",
    description:
      "Este programa significa para mí una gran oportunidad de incorporarme a un campo que siempre me había interesado, pero en el que no sabía cómo comenzar. ",
    background: true,
    image: "/images/slider/jesus-figueroa-min.jpg",
  },
  {
    title: "Karen Velásquez",
    description:
      "Gracias al programa he aprendido conceptos teóricos y habilidades que me permitirán convertirme en científico de datos. Además, me emociona saber que podremos ayudar a ONGs en nuestro país. ",
    background: true,
    image: "/images/slider/karen-velasquez-min.jpg",
  },
  
  {
    title: "",
    description: "",
    background: false,
    image: "/images/slider/sld3.png",
  },
]

function Slider() {
  const [slidePrevious, setSlidePrevious] = useState(sliders.length - 1)
  const [slide, setSlide] = useState(0)
  const [slideNext, setSlideNext] = useState(1)

  function decreaseSlide() {
    setSlideNext(slide)
    setSlide(slidePrevious)
    if (slidePrevious - 1 === -1) {
      setSlidePrevious(sliders.length - 1)
    } else {
      setSlidePrevious(slidePrevious - 1)
    }
  }

  function increaseSlide() {
    setSlidePrevious(slide)
    setSlide(slideNext)
    if (slideNext + 1 === sliders.length) {
      setSlideNext(0)
    } else {
      setSlideNext(slideNext + 1)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      increaseSlide()
    }, 5000)
  })

  return (
    <SliderMainDiv>
      <SliderLeftImg img={sliders[slidePrevious].image} onClick={decreaseSlide}>
        <IconContext.Provider value={{ color: "white", size: "3em" }}>
          <FaChevronLeft />
        </IconContext.Provider>
      </SliderLeftImg>
      <SliderCentralDiv>
        <SliderMainImg src={sliders[slide].image} alt="main_slider_img" />
        <SliderMsgDiv deactive={!sliders[slide].background}>
          <IconContext.Provider value={{ color: "#0B9299", size: "1.5em" }}>
            <FaQuoteLeft hidden={!sliders[slide].background} />{" "}
          </IconContext.Provider>
          <p>{sliders[slide].description}</p>
          <NameDiv>{sliders[slide].title}</NameDiv>
        </SliderMsgDiv>
      </SliderCentralDiv>
      <SliderRigthImg img={sliders[slideNext].image} onClick={increaseSlide}>
        <IconContext.Provider value={{ color: "white", size: "3em" }}>
          <FaChevronRight />
        </IconContext.Provider>
      </SliderRigthImg>
    </SliderMainDiv>
  )
}

export default Slider
