import React , {useEffect, useState} from "react"

import "../../static/global.css"
//importación de componentes
import MainLayout from "../components/Layouts/MainLayout"
import Video from "../components/Video"
import Picture from "../components/Picture"
import KnowMore from "../components/KnowMore"
import Partners from "../components/Partners"
import Slider from "../components/Slider"
import SliderPrueba from "../components/SliderPrueba"
import SliderPrueba2 from "../components/SliderPrueba2"
import useContentful from "../utils/useContentful";
import styled from "styled-components"
import { Helmet } from "react-helmet"


const HomeEntryId = "7uK6O2nrkhZdDezj3BgHDF";
const FlyerEntryId = "3VU18wi40hGb9A6wMdawQx"


const Button = styled.button`
  margin-left: auto;
  margin-right: auto;
  width: 107px;
  height: 37px;
  background:#008991;
  border-radius: 8px;
  border: solid 1px #008991;
  cursor: pointer;
  outline: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 136.8%;
  letter-spacing: 0.03em;
  color: #ffffff;

a{
  color: #FFFFFF;
}
`
const Modal = styled.div`
 position:fixed ;
display:flex; 
justify-content:center;
align-items:flex-start;
top:20px;
width: auto;
height:95% ;
left:50% ;
padding:28px 24px 24px 24px;
transform:translateX(-50%) ;
display: ${p=> p.show ? "flex" : "none"} ;
background-color:white ;
border-radius:16px ;
box-shadow: 2px 2px 22px 4px rgba(18, 47, 91, 0.2);
aspect-ratio:0.9 ;
z-index:10;
.modal-image{
  aspect-ratio:1 ;
  width:100%;
  height:auto ;
  display: flex;
  gap:8px ;
  justify-content: center;
  flex-direction: column;
  margin-top:16px ;
  align-items: center;
 
}

img{
  width:100%;
  height:100% ;
  object-fit:fill;
}

svg{
  position: absolute;
  top:12px ;
  right:12px ;
  width:24px ;
  height:24px ;
  cursor: pointer;
}


@media (max-width: 700px) {
  width: 100%;
height:auto ;
transform:translate(-50%,50%) ;
  }

`


export default function Home() {

  const { data: home, fetched } = useContentful({ id: HomeEntryId });
  const { data: flyer, fetched2 } = useContentful({ id: FlyerEntryId });
  const [show , setShow] = useState(false)

console.log("home",home)
useEffect(()=>{
flyer  && setShow(flyer.fields.show)
}, [flyer])

  return (
    <>
    <Helmet>
    
    <link
              rel="alternate"
              hreflang="es-pe"
              href="https://www.datascienceglobalskills.pe/"
            />
            <link rel="canonical" href="https://www.datascienceglobalskills.pe/" />
    </Helmet>
     {(fetched && flyer)? (
<>
      <MainLayout active={1}>
      <Video  source ={home.fields.programaVideo.fields.file.url}/>
      <br />
      <br />
      <br />
      <KnowMore data={home.fields.programaSecciones} inicio={home.fields.proximoInicio} dejanoscorreo={home.fields.textsendbrochure} col={home.fields.programaBackgroundColor}/>
      <Partners data={home.fields.programaPartners}/>
      <br />
      <br />
      <br />
      {/* <Slider /> */}
      <SliderPrueba2 data={home.fields.programaSlider}/>
      <br />
      <br />
      <br />
      
    </MainLayout>
    <Modal show={show}>
      <div className="modal-image">
      <img src={flyer.fields.image.fields.file.url}/>
      <a href={flyer.fields.buttonLink}><Button> {flyer.fields.buttonText}</Button></a>
      </div>

<svg onClick={()=> setShow(false)} width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M205.7 194.3C207.202 195.817 208.045 197.865 208.045 200C208.045 202.135 207.202 204.183 205.7 205.7C204.171 207.179 202.127 208.005 200 208.005C197.873 208.005 195.829 207.179 194.3 205.7L128 139.3L61.7001 205.7C60.171 207.179 58.1271 208.005 56.0001 208.005C53.873 208.005 51.8292 207.179 50.3001 205.7C48.7978 204.183 47.9551 202.135 47.9551 200C47.9551 197.865 48.7978 195.817 50.3001 194.3L116.7 128L50.3001 61.7002C49.0244 60.1458 48.3724 58.1725 48.4711 56.1641C48.5697 54.1557 49.4119 52.2557 50.8338 50.8339C52.2556 49.412 54.1556 48.5698 56.164 48.4712C58.1724 48.3725 60.1457 49.0245 61.7001 50.3002L128 116.7L194.3 50.3002C195.854 49.0245 197.828 48.3725 199.836 48.4712C201.845 48.5698 203.745 49.412 205.166 50.8339C206.588 52.2557 207.43 54.1557 207.529 56.1641C207.628 58.1725 206.976 60.1458 205.7 61.7002L139.3 128L205.7 194.3Z" fill="black"/>
</svg>

    </Modal>
</>
    
     ): <div
     style={{
       width: "100vw",
       height: "100vh",
       display: "flex",
       justifyContent: "center",
       marginTop: "0px",
       padding: "15%",
     }}
   >
     {" "}
     <svg
       version="1.1"
       id="L9"
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 100 100"
       enableBlackground="new 0 0 0 0"
       xmlSpace="preserve"
     >
       <path
         fill="#008991"
         d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
       >
         <animateTransform
           attributeName="transform"
           attributeType="XML"
           type="rotate"
           dur="1s"
           from="0 50 50"
           to="360 50 50"
           repeatCount="indefinite"
         />
       </path>
     </svg>
   </div>} 


</>
    )
}
