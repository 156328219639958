import React, { useState, useEffect, useRef } from "react"
import { navigate } from "@reach/router"
import Lottie from "react-lottie"
import animationData from "../../static/images/knowMore/hexagono.json"
import styled from "styled-components"
import BoldContentful from "../utils/BoldContentful"
import color from "../config/colors.json"
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(200px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }
  
`;

const StyledContainer = styled.div`
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
  background-color: ${p => p.col};
  border-radius: 32px;
  padding-bottom: 6rem;

  ul {
    list-style-type: none;
    margin-top: 1rem;
    margin-bottom: 0rem;
    margin-left: 2rem;
    margin-right: 2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 136.8%;
    /* or 25px */
    letter-spacing: 0.1em;
    /* light letter */
    color: #767b8a;
    @media (min-width: 700px) {
      font-size: 15px;
      margin-bottom: 2rem;
      margin-left: 4rem;
      margin-right: 4rem;
    }
    @media (min-width: 1050px) {
      font-size: 18px;
    }
  }
  li {
    padding-left: 2rem;
    background-image: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z" fill="%2303878F"/></svg>');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-position-y: center;
  }
  @media (min-width: 1180px) {
    width: 70%;
  }
  a {
    color: #767b8a;
    text-decoration: none;
  }
  .link {
    color: #008991;
    font-weight: 500;
  }
  .link:hover {
    text-decoration: underline;
  }
`
const StyledForm = styled.div`
.tf-v1-widget{
  width:100% ;
}

@media (min-width: 900px){
  .tf-v1-widget{
  width:600px ;
}
}

`


const SectionTitle = styled.div`
  padding-right: 1rem;
  margin-top: 2rem;
  margin-bottom: 0px;
  margin-left: 2rem;

  display: inline-block !important;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150.3%;
  /* identical to box height, or 45px */
  text-align: left;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #262626;

  &.animate {
    animation: fade-up 2s;
  }

 /* &.animate-fade-up-a {
  animation: fade-up 4s;
}

&.animate-fade-up-b {
  animation: fade-up 8s;
}

&.animate-fade-up-c {
  animation: fade-up 12s;
}*/

  @media (min-width: 700px) {
    padding-left: 1rem;
    font-size: 26px;
    margin-left: 3rem;
  }
  @media (min-width: 1050px) {
    font-size: 30px;
  }

  
`
const SSContainer = styled.div`
 &.animate {
    animation: fade-up 2s;
  }
`

const SectionSubtitle = styled(SectionTitle)`
  font-size: 17px;
  color: #008991;
/*
  opacity: 0;
  transform: translateY(200px) scale(0.9);
  transition: opacity 7s ease, transform 7s ease;
  &.fade-up {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }*/
  &.animate {
    animation: fade-up 4s;
  }




  @media (min-width: 700px) {
    font-size: 20px;
  }
  @media (min-width: 1050px) {
    font-size: 24px;
  }
 
`
const SectionSubtitleTextSimple = styled(SectionTitle)`
  font-size: 17px;
  color: #008991;
/*
  opacity: 0;
  transform: translateY(200px) scale(0.9);
  transition: opacity 7s ease, transform 7s ease;
  &.fade-up {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }*/
  &.animate {
    animation: fade-up 4s;
  }
  @media (min-width: 700px) {
    font-size: 20px;
  }
  @media (min-width: 1050px) {
    font-size: 24px;
  }
 
`
const SectionSubtitleCards = styled(SectionTitle)`
  font-size: 17px;
  color: #008991;
/*
  opacity: 0;
  transform: translateY(200px) scale(0.9);
  transition: opacity 7s ease, transform 7s ease;
  &.fade-up {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }*/
  &.animate {
    animation: fade-up 4s;
  }
  @media (min-width: 700px) {
    font-size: 20px;
  }
  @media (min-width: 1050px) {
    font-size: 24px;
  }
 
`

const SaberMasSection = styled.div`
  ul {
    display: grid;
  }
  li {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`
const SectionParagraph = styled.p`
  margin-top: 1rem;
  margin-bottom: 0rem;
  margin-left: 2rem;
  margin-right: 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 136.8%;
  /* or 25px */
  letter-spacing: 0.1em;
  /* light letter */
  color: #767b8a;
  @media (min-width: 700px) {
    font-size: 15px;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  @media (min-width: 1050px) {
    font-size: 18px;
  }

  &.animate {
    animation: fade-up 2s;
  }

`
const StyledImg = styled.img`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
`

const StyledFixedContainer = styled.div`
  width: 20%;
  display: none;
  flex-direction: column;
  @media (min-width: 1180px) {
    display: flex;
  }
`

const Number = styled.h1`
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #0b9299;
  color: white;
  font-size: 8rem;
  padding-left: 5rem;
`
const StickyDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 15vh;
  display: inline-block;
  padding-top: 15vh;
  padding-bottom: 5vh;
`

const StyledLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150.3%;
  /* verde */
  color: #0b9299;
  opacity: 0.4;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 8px;
  padding-top: 8px;
  border-left: solid 4px black;
  ${({ active }) =>
    active &&
    `color: #0B9299;
    font-weight: bold;
    border-left: solid 4px  #0B9299;
  `}
`

const ContainerList = styled.div`
  display: grid;
  grid-template-columns: auto;
  &.animate {
    animation: fade-up 4s;
  }
  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
`

const MainContainer = styled.div`
  display: flex;
`

const SectionNextCourse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;
`
const Components = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 4rem;
  margin-left: 4rem;
  justify-content: space-between;
  &.animate {
    animation: fade-up 4s;
  }

  @media (max-width: 870px) {
    flex-direction: column;
    margin-right: 0rem;
    margin-left: 0rem;
    width: 100%;
  }
`

const Subcomponent = styled.div`
  background-color: ${p => p.col};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0rem;
  padding: 1rem;
align-items: center;
flex:1;
  img{
    width: 50%;
    padding:16px ;
  }
  ul {
    margin: 0px 0rem;
  }
  li {
    color: ${p => p.coltext};
    background-image: url("/images/checklogo.svg");
    margin-top: 1rem;
    margin-bottom: 2rem;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-position-y: 8px;
    background-size: 12px 12px;
    font-size: 16px;
    padding: 0px 0px 0px 20px;
    @media (max-width: 870px) {
      font-size: 13px;
    }
  }

  h1 {
    display: flex;
    justify-content: center;
    color: #767b8a;
    font-weight: 600;
    font-size: 20px;
    margin-top: 16px;
    @media (max-width: 870px) {
      font-size: 16px;
    }
  }

  @media (max-width: 870px) {
    position: relative;
    width: 90%;
    margin: 0rem 0rem 1rem 0rem;
   
  }
`
const AprenderasSection = styled.div`
  ul {
    margin-top: 0px;
    li{
background-image:${p => `url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z" fill="${p.col}"/></svg>')` };
    }
  }
  li {
    margin-bottom: 2rem;
    margin-top: 0rem;
    li {
    padding-left: 2rem;
    
    background-position: 0 0;
    background-repeat: no-repeat;
    background-position-y: center;
  }
    background-position: 0 0;
    background-repeat: no-repeat;
    background-position-y: 10px;
  }
  h {
  }
  a{
    color:#0b9299;
  }

  &.animate {
    animation: fade-up 6s;
  }
`

const Button = styled.button`
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  height: 37px;
  background: #d85454;
  border-radius: 8px;
  border: solid 1px #ec5959;
  cursor: pointer;
  outline: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 136.8%;
  letter-spacing: 0.03em;
  color: #ffffff;
  opacity: 0.4;

  @media (min-width: 700px) {
    font-size: 16px;
    width: 200px;
  }
  @media (min-width: 1050px) {
    font-size: 18px;
    width: 238px;
  }
`

const Buttonemail = styled.button`
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  height: 37px;
  background: #d85454;
  border-radius: 8px 8px 8px 8px;
  border: solid 1px #ec5959;
  cursor: pointer;
  outline: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 136.8%;
  letter-spacing: 0.03em;
  color: #ffffff;

  :hover {
    background: #eb7676;
  }
  @media (min-width: 700px) {
    font-size: 16px;
    width: 160px;
    border-radius: 0px 8px 8px 0px;
  }
  @media (min-width: 1050px) {
    font-size: 18px;
    width: 160px;
  }
`
const Emailcontainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`
const Inputemail = styled.input`
  height: 37px;
  width: 320px;
  border: 1px solid #767b8a;
  border-radius: 8px 0px 0px 8px;
  padding: 0px 12px;

  @media (max-width: 700px) {
    border-radius: 8px 8px 8px 8px;
    width: 80vw;
  }
`

const Buttoncontainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;

  @media (max-width: 700px) {
    flex-direction: column;
    gap: 12px;
  }
`

const DivCardhome = styled.div`
 width: 31% ;
display: flex ;
align-items: center;
flex-direction:column;
  @media (max-width: 700px) {
    width: 100% ;
  }
`

{/*const AllPage = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("yourElementId");
      if (element) {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementTop < windowHeight) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);*/}


const Textsimple = ({ data }) => {

  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 250) {
        setShow(true);
      } else {
        setShow(false);
      }}
      else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
    {data.fields.title && 
    <SectionSubtitleTextSimple 
    style={{ color: `${color[data.fields.titleColor]}`, marginTop: '0px', marginBottom: '0px' }}
    
    >
      {data.fields.title}
    </SectionSubtitleTextSimple>
      
      
      }
      <SectionParagraph className={show ? "animate" : ""}>
        {BoldContentful(data.fields.content, "#767B8A")}
      </SectionParagraph>
    </>
  )
}

const Cardsitem = ({ data }) => {


  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 250) {
        setShow(true);
      } else {
        setShow(false);
      }}
      else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);




 console.log("DATAA",data)
  return (
    <>
      <SectionSubtitleCards style={{color: `${color[data.fields.titleColor]}`}}
      className={show ? "animate" : ""}>{data.fields.title}</SectionSubtitleCards>
      <Components className={show ? "animate" : ""}>
        {data.fields.content.map(itemcard => {
          return (
            <DivCardhome >
            <img style={{width: "50%" , padding:"16px"}}src={itemcard.fields.image.fields.file.url}></img>
            <Subcomponent col={ !!itemcard.fields.backgroundCustom ?itemcard.fields.backgroundCustom :color[itemcard.fields.backgroundColor]}  coltext={itemcard.fields.colorText}>
             
              <h1 style={{color: `${itemcard.fields.colorText}`}} >{itemcard.fields.title} </h1>
              <ul style={{color: `${itemcard.fields.colorText}`}}>{BoldContentful(itemcard.fields.list, `${itemcard.fields.colorText}`)}</ul>
            </Subcomponent>
            </DivCardhome>
          )
        })}
      </Components>
    </>
  )
}

const Lists = ({ data }) => {

  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 950) {
        setShow(true);
      } else {
        setShow(false);
      }}
      else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


 console.log("liist",data)
  return (
    <>
      <SectionSubtitle style={{color: `${color[data.fields.titleColor]}`}}
      className={show ? "animate" : ""}
      >{data.fields.title}</SectionSubtitle>
      <AprenderasSection col ={color[data.fields.titleColor]?.replace('#','%23')}
      className={show ? "animate" : ""}>
        {BoldContentful(data.fields.listText, "#767B8A")} 
      </AprenderasSection>
    </>
  )
}

const Twoimages = ({ data }) => {

  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 1050) {
        setShow(true);
      } else {
        setShow(false);
      }}
      else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <ContainerList className={show ? "animate" : ""}>

          <SectionNextCourse>

                <StyledImg src={data.fields.image1.fields.file.url} alt="certificado" />

                <SectionParagraph style={{ marginTop: "0", textAlign: "center" }}>
                  {data.fields.description1}
                </SectionParagraph>

          </SectionNextCourse>

          <SectionNextCourse>

                <StyledImg src={data.fields.image2.fields.file.url} alt="certificado" />

                <SectionParagraph style={{ marginTop: "0", textAlign: "center" }}>
                  {data.fields.description2}
                </SectionParagraph>

          </SectionNextCourse>

    </ContainerList>
  )
}

function Knowmore({ data , inicio , dejanoscorreo ,col}) {
  const [sectionNumber, setSectionNumber] = useState(1)
  const [okemail, setOkemail] = useState(false)
  const [buttonmsg, setButtonmsg] = useState("Enviar brochure")
  const [success, setSuccess] = useState(false)
  const [correo, setCorreo] = useState("")
  const typeformRef = useRef(null);
  const handleScroll = () => {
    const position = window.pageYOffset
    if (position < 2600) {
      setSectionNumber(1)
    } else {
      if (position < 3100) {
        setSectionNumber(2)
      } else {
        if (position < 3670) {
          setSectionNumber(3)
        }
      }
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true
    }
    alert("Correo electronico no valido")
    return false
  }

  const sendBrochure = async correo => {
    const bodydata = {
      email: correo,
    }

    if (ValidateEmail(correo)) {
      try {
        setButtonmsg("Enviando...")
        const data = await fetch(
          "https://app-landing-aporta.azurewebsites.net/api/EnviarBrochure",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: " */*",
            },
            body: JSON.stringify(bodydata),
          }
        )
        const response = await data.json()
        if (response.error) {
          setButtonmsg("Enviar brochure")
          setSuccess(false)
        
        } else {
          setSuccess(true)
          setButtonmsg("Enviar brochure")
          setTimeout(() => {
            setSuccess(false)
            setCorreo("")
          }, 8000)
        }
      } catch (error) {
        console.log(error)
        setButtonmsg("Enviar brochure")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])



  const contentstyle = ( data ) => {
   
    if (data.sys.contentType.sys.id == "programaSeccionText") {
      return <Textsimple data={data}></Textsimple>
    } else if (data.sys.contentType.sys.id == "programaSeccionCards") {
      return <Cardsitem data={data}></Cardsitem>
    } else if (data.sys.contentType.sys.id == "programaSeccionList") {
      return <Lists data={data}></Lists>
    } else if (data.sys.contentType.sys.id == "programaSeccionTwoImages") {
      return <Twoimages data={data}></Twoimages>
    }
  }
console.log("data",data)


const [show, setShow] = useState(false);
useEffect(() => {
  const handleScroll = () => {
    if(window.screen.width > 600){
    if (window.scrollY > 250) {
      setShow(true);
    } else {
      setShow(false);
    }
  }else{
    setShow(true);
  }
  };
  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);

const getAnimationClass = (index) => {
  switch (index) {
    case 0:
      return "animate-fade-up-a";
    case 1:
      return "animate-fade-up-b";
    case 2:
      return "animate-fade-up-c";
    default:
      return "animate-fade-up-c"; // Clase de animación por defecto
  }
};


  return (
<>
    <MainContainer>
       <StyledContainer col={col} >
      {data.map((item, index) => {
   
        return (
          <>
            {item.fields.title && (
              <SectionTitle style={{color: `${color[item.fields.titleColor]}`}}
              className={show ? "animate" : ""}
              >
                {item.fields.title} </SectionTitle>
            )}

{item.fields.content.map((contentItem, contentIndex) => {
        return (
          <div key={contentIndex} className={getAnimationClass(contentIndex)}>
            {contentstyle(contentItem)}
          </div>
)
             
            })}
          </>
        )
      })}

          <Emailcontainer >

                  <SectionParagraph > {dejanoscorreo}</SectionParagraph>
                  <StyledForm data-tf-live="01J1AZ982WBJREQRDSD3HXYD8V" ref={typeformRef}></StyledForm>
        

                  {success && <SectionParagraph style={{color: '#008991', margin: '0px 0px 0px 0px'}}> Enviado correctamente , revise su bandeja de entrada </SectionParagraph>} 
          </Emailcontainer>



          
          <SectionParagraph style={{ fontWeight: "bold" , textAlign: 'center' }}>
            {inicio}
          </SectionParagraph>
    
    </StyledContainer>

    <StyledFixedContainer>
        <StickyDiv>
          {data.map((item, index) => {
            return (
              <StyledLabel
                key={item.fields.name.toUpperCase()}
                active={sectionNumber === index + 1 ? true : false}
              >
                {item.fields.name.toUpperCase()}
              </StyledLabel>
            )
          })}

          <Number>{"0" + sectionNumber}</Number>
        </StickyDiv>
    </StyledFixedContainer>
</MainContainer>
</>
  )
}

export default Knowmore;


{/*    <SectionTitle>
          FORMA PARTE DE LA SIGUIENTE GENERACIÓN DE DATA SCIENTISTs DEL PERÚ
        </SectionTitle>
        <SectionParagraph>
          El <strong>Advanced Program in Data Science & Global Skills</strong> está diseñado y
          desarrollado por BREIT, Instituto de Tecnología Avanzada y Aporta en partnership con el MIT
IDSS (Institute for Data, Systems, and Society del
Massachusetts Institute of Technology).
        </SectionParagraph>
        <SectionParagraph>
          Formamos profesionales con alto potencial y talento en Data Science,
          potenciando capacidades a través de la exposición al mundo real en
          distintos entornos, y brindando una sólida formación técnica
          proporcionada por el MIT.
        </SectionParagraph>
        <SectionParagraph>
        Desde el 2019 potenciamos a futuros líderes y agentes de
cambio en el campo de la ciencia de datos. Hoy contamos
con 6 promociones y más de 130 profesionales que han
recibido una formación integral incorporando el
aprendizaje de habilidades globales y una mirada humana
y social de los datos.
        </SectionParagraph>
        <SectionSubtitle>
        TRES COMPONENTES DEL PROGRAMA
        </SectionSubtitle>
<Components>
<Subcomponent>
<h1>COMPONENTE TÉCNICO</h1>
<ul>
<li>Acceso a cursos online del MITx MicroMasters
Program in Statistics and Data Science</li>
<li>Clases semanales sincrónicas dictadas por el
MIT (beneficio único por ser parte del Advanced
Program)</li>
<li>Clases dictadas por profesores locales</li>
</ul>
</Subcomponent>

<Subcomponent>
<h1>GLOBAL SKILLS</h1>
<ul>
<li>Módulos en Autogestión, Liderazgo,
Comunicación y Marca Personal</li>
<li>Acompañamiento cercano y personalizado</li>
<li>Masterclasses en metodologías y herramientas
actuales</li>
</ul>
</Subcomponent>

<Subcomponent>
<h1>RETO INTEGRATIVO</h1>
<ul>
<li>Diseño de una solución de analítica con data
real para organizaciones sociales</li>
<li>Acompañamiento técnico y metodológico por
expertos locales e internacionales (MIT IDSS)</li>
<li>Trabajo en equipo y gestión de proyectos</li>
</ul>
</Subcomponent>


</Components>

        
        <SectionSubtitle>¿QUÉ APRENDERÁS?</SectionSubtitle>
        <AprenderasSection>
        <ul>
        <li><p>
        Dominio de los fundamentos de ciencia de datos y analítica avanzada: estadística,
machine learning, y probabilidad para la toma de decisiones.
        </p></li>
        <li><p>
        Habilidades globales que potenciarán tu desarrollo personal y profesional para
desempeñarte con éxito en el mundo laboral: liderazgo, auto-gestión y marca
personal.
        </p></li>
        <li><p>
        Competencias para consolidarte como agente de cambio: comunicación efectiva,
pensamiento crítico, gestión de proyectos y data science para el impacto social.
        </p></li>
        </ul>
        </AprenderasSection>
        <SectionNextCourse
    
        >
    

  <Emailcontainer >

<SectionParagraph > Dejanos tu correo electronico para enviarte nuestro brochure</SectionParagraph>
<Buttoncontainer >
<Inputemail  type="text" onChange={(e) =>{setCorreo(e.target.value); setSuccess(false)}} value={correo}></Inputemail>
<Buttonemail  onClick={() => sendBrochure(correo)}>{buttonmsg}</Buttonemail>

</Buttoncontainer>
{success && <SectionParagraph style={{color: '#008991', margin: '0px 0px 0px 0px'}}> Enviado correctamente , revise su bandeja de entrada </SectionParagraph>} 
  </Emailcontainer>



          
          <SectionParagraph style={{ fontWeight: "bold" }}>
            Próximo inicio: Mayo 2023
          </SectionParagraph>
    
        </SectionNextCourse>
        <SectionTitle>CERTIFICACIÓN</SectionTitle>
        <ContainerList>
          <SectionNextCourse>
            <StyledImg
              src="/images/knowMore/certificado.png"
              alt="certificado"
            />
            <SectionParagraph style={{ marginTop: "0", textAlign: "center" }}>
              Advanced Program in Data Science & Global Skills con el
              reconocimiento del MIT IDSS (Institute for Data, Systems and
              Society)
            </SectionParagraph>
          </SectionNextCourse>
          <SectionNextCourse>
            <StyledImg
              src="/images/knowMore/certificado.png"
              alt="certificado"
            />
            <SectionParagraph style={{ marginTop: "0", textAlign: "center" }}>
              Certificado del Micromasters in Data Science and Statistics
              otorgado por MITx.
            </SectionParagraph>
          </SectionNextCourse>
        </ContainerList>
        <SectionTitle>¿QUIERES SABER MÁS?</SectionTitle>
        <SaberMasSection>
          <ul>
            <li>
              Lee nuestro caso de éxito en{" "}
              <a
                className="link"
                href="https://news.mit.edu/2020/creating-next-generation-data-scientists-peru-0309"
                target="_blank"
              >
                MIT News
              </a>
            </li>
            <li>
            <a
                className="link"
                href="https://idss.mit.edu/news/partnering-to-advance-data-science-education/"
                target="_blank"
              >
                Co-diseños
              </a>
            {" "} que impulsan la educación
              
            </li>
            <li>
            Nuestra {" "} 
              <a
                className="link"
                href="https://idss.mit.edu/news/a-trail-blazing-cohort/"
                target="_blank"
              >
                Primera Promoción
              </a>
            </li>
            <li>
            <a
                className="link"
                href="https://elcomercio.pe/tecnologia/ciencias/salud-prevenciometro-usan-analisis-de-datos-para-prevenir-el-cancer-noticia/"
                target="_blank"
              >
                Prevenciómetro
              </a>
              {" "}| Usan análisis de datos para prevenir el cáncer
              
            </li>
            <li>
            El futuro de {" "}
              <a
                className="link"
                href="https://idss.mit.edu/news/perus-breit-future/"
                target="_blank"
              >
                BREIT
              </a>
            </li>
          </ul>
        
        </SaberMasSection>*/}