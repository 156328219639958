import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import { Col } from "reactstrap"
import styled from "styled-components"

/*const DivStyled = styled.div`
  display: flex;
  align-content: center;
  padding: 0px;
  margin: 0px;
`
*/
const Container = styled.div`
  /* height: 600px;
  width: 100%; */
`

const Video = ({source}) => {
  //source of the video to be defined

  return (
    <>
      <Container>
        <video
          width="100%"
          height="100%"
          autoPlay="autoplay"
          loop="loop"
          muted
        >
          <source src={source} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Container>
    </>
  )
}

export default Video
